<template>
  <div class="tablezone">
    <div class="uptable-controls tablezone__controls" style="align-items: center;">
      <Btn :small="true" class="tablesave btn btn_blue" @click="saveTable">Сохранить таблицу</Btn>
      <Btn :small="true" class="tablesave btn btn_blue" @click="toggleComparison" v-if="selectedDates.length === 2">
        {{ selectEnabled ? 'Отменить сравнение' : 'Сравнить' }}
      </Btn>
      <Btn :small="true" class="tablesave btn btn_blue" @click="toggleLimit">
        Показать {{ limit === 100 ? (isZaprosi? 'больше' : Math.min(filterednames.length, 5000)) : Math.min(zaprosiValueFiltered.total || filterednames.length, 100) }}
      </Btn>
      <div style="font-weight: 700; font-size: 12px;">Сейчас показаны {{ Math.min(limit, zaprosiValueFiltered.total ||
        displayedNames.length) }} строк
      </div>
    </div>
    <div style="width: 100%; padding-top: 30px; margin-top: -30px;">
      <table class="table">
        <thead>
          <tr v-for="row in summaryRows" :key="row.label">
            <td :colspan="colSpan"><b>{{ row.label }}</b></td>
            <td v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date"
              :class="getSummaryClass(row, index)">
              <b>{{ getSummaryValue(row, worksheet, index) }}</b>
            </td>
          </tr>
          <tr>
            <td :colspan="colSpan"><b>Дата:</b></td>
            <td v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date"
              @click.exact="setSortDate(filteredDates[index])"
              @click.ctrl="selectEnabled ? null : selectDate(worksheetdates[index])"
              :class="{ selectedDate: selectEnabled || selectedDates.includes(worksheetdates[index]) }">
              <button class="datecell__delete" v-if="Role === 2" @click="deleteWorksheets(index)">×</button>
              <b>{{ filteredDates[index] }}</b>
              <span class="sortarrow" :class="{ sortarrow_reverse: sortreverse }"
                v-if="sortdate === filteredDates[index]">↓</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-if="!isZaprosi">
            <tr v-for="name in displayedNames" :key="name + ' - ' + project.name">
              <td class="link">
                <div>{{ name }}</div>
              </td>
              <td v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date + ' - ' + project.name"
                :class="getCellClass(name, index)">
                {{ worksheet.data.table[name]?.[worksheetMode] || 0 }}
              </td>
            </tr>
          </template>
          <template v-if="isZaprosi">
            <template v-for="name in zaprosiValueFiltered.names" :key="name + ' - ' + project.name">
              <tr v-for="query in zaprosiValueFiltered.v[name]" :key="query">
                <td class="link">
                  <div><span v-if="sortedWorksheet">[{{ zaprosiValue?.[name]?.selectedSum || 0 }}]</span> {{ name }}</div>
                </td>
                <td class="link zaprosilink">
                  <div>{{ query }}</div>
                </td>
                <td v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date + ' - ' + project.name"
                  :class="getCellClass(name, index, query)">
                  {{ worksheet.data.table[name]?.data?.[query]?.[worksheetMode] || 0 }}
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import Btn from './buttons/Btn.vue'
import { useTableUI, useTableUiRefs } from './composables/useTableUi.js'
import { useWorksheetData } from './composables/useWorksheetData.js'
import { useTableOperations } from './composables/useTableOperations.js'

const store = useStore()
const project = computed(() => store.state.project)
const worksheetMode = computed(() => store.state.worksheetMode)
const Role = computed(() => store.state.Role)
const colSpan = computed(() => isZaprosi.value ? 2 : 1)
const { sortdate, sortreverse, selectEnabled, selectedDates, limit } = useTableUiRefs()
const UIrefs = { sortdate, sortreverse, selectEnabled, selectedDates, limit }

const {
  worksheetdates,
  filteredWorksheets,
  filteredDates,
  percentages,
  filterednames,
  zaprosiValue,
  zaprosiValueFiltered,
  isZaprosi,
  mode,
  sortedWorksheet
} = useWorksheetData(limit, sortdate, sortreverse, selectEnabled, selectedDates)

const {
  displayedNames,
  setSortDate,
  selectDate,
  toggleLimit,
  toggleComparison,
  getSummaryClass,
  getCellClass,
  green,
  red
} = useTableUI(UIrefs, isZaprosi, filteredWorksheets, filterednames, worksheetMode)

const { deleteWorksheets, save } = useTableOperations(isZaprosi, zaprosiValueFiltered)

const summaryRows = [
  { label: 'Итого:', valueFn: w => w.data[mode.value], compare: (a, b) => a < b ? 'bad' : a > b ? 'good' : '' },
  { label: 'CTR:', valueFn: w => w.data.CTR, format: v => `${v}%`, compare: (a, b) => a < b ? 'bad' : a > b ? 'good' : '' },
  { label: 'Процент от прошлого месяца:', valueFn: (_, i) => i === filteredWorksheets.value.length - 1 ? '' : percentages.value[i], format: v => v ? `${v}%` : '', compare: v => v > 100 ? 'good' : v < 100 ? 'bad' : '' },
  { label: 'Заполненность месяца данными:', valueFn: w => w.percentage, format: v => typeof v === 'number' ? `${v}%` : '?' }
]

const getSummaryValue = (row, worksheet, index) => row.format ? row.format(row.valueFn(worksheet, index)) : row.valueFn(worksheet, index)

const saveTable = () => save({
  project: project.value,
  filteredWorksheets: filteredWorksheets.value,
  filteredDates: filteredDates.value,
  filterednames: filterednames.value,
  zaprosiValue: zaprosiValue.value,
  worksheetMode: worksheetMode.value,
  isZaprosi: isZaprosi.value,
  summaryRows,
  getSummaryValue,
  getSummaryClass,
  getCellClass,
  green,
  red
})

watch(filteredDates, v => { if (sortdate.value && !v.includes(sortdate.value)) sortdate.value = false }, { deep: true })
watch(worksheetdates, v => {
  if (selectedDates.value.some(el => !v.includes(el))) {
    selectedDates.value = []
    selectEnabled.value = false
  }
}, { deep: true })
</script>

<style lang="sass" scoped>
$danger: #dc3545
$success: #198754
$primary: #0d6efd

.sortarrow
  line-height: 0
  color: red
  font-weight: 900
  margin-left: 4px
  font-size: 20px
  display: inline-block
  &_reverse
    transform: scale(1,-1) translateY(-3px)
.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px
  .selectedDate
    border: 2px solid $primary !important
  thead
    position: sticky
    top: 0
    z-index: 4
    &::after
      content: ''
      background: white 
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      top: 0
      display: block
      z-index: -1
    td
      background: white
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  td:first-child
    width: 300px
    min-width: 300px
    max-width: 300px
  tr
    font-weight: 500
    white-space: nowrap
  td.bad
    background: rgba(255,200,200) !important
  td.good
    background: rgb(200,255,200) !important
  td.active
    background: white !important
    position: relative
    padding-right: 15px
    &::after
      position: absolute
      content: '↑'
      right: 2px
      font-size: 18px
      top: 50%
      transform: translateY(-65%)
      transform-origin: center center
  td.filtered
    outline: 2px solid violet
    .datecell__count
      opacity: 1
      pointer-events: auto
  .inversed .active::after
    content: '↓'

  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.group
      text-align: left
      width: 150px !important
      max-width: 150px !important
      min-width: 150px !important
      position: relative !important
    &.zaprosilink
      text-align: left
      z-index: 1 !important
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.8)
.datecell
  position: relative
  &__delete
    width: 20px
    background: rgba(255,255,255,.05)
    border: none
    position: absolute
    pointer-events: none
    opacity: 0
    bottom: 100%
    line-height: 0
    right: -11px
    z-index: 100
    background: rgba(0,0,0,.5)
    width: 17px
    height: 17px
    border-radius: 9999px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: 700
    color: red
    padding: 3px
    pointer-events: none
    transition: opacity .3s ease-out
    cursor: pointer
    opacity: 0
  &:hover &__input, &:focus-within &__input
    pointer-events: auto
    opacity: 1
  &__count
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    text-align: center
    width: auto
    min-width: 30px
    padding: 2px 8px
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 5px)
    border: none
    background: white
    border: 2px solid violet
  &__input
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    width: 100px
    position: absolute
    right: 100%
    top: 0
    bottom: 0
    border: none
    background: white
    border: 2px solid black
    &_broken
      border: 2px solid red !important
  &:hover &__delete 
    opacity: 1
    pointer-events: auto
  &:hover &__delete:hover
    background: black
</style>