import { computed } from 'vue'
import { useStore } from 'vuex'

const getType = (el) => {
  if (el[0] !== '|') return ''
  switch (el[1]) {
    case '-':
      if (el[2] == '-')
        return '|--'
      return '|-'
    case '+':
      if (el[2] == '+')
        return '|++'
      return '|+'
    default: return ''
  }
}

export function useWorksheetData(limit, sortdate, sortreverse, selectEnabled, selectedDates) {
  const store = useStore()

  const worksheetMode = computed(() => store.state.worksheetMode)
  const exceptions = computed(() => store.getters.exceptions)
  const filterregexs = computed(() => store.getters.filterregexs)
  const isZaprosi = computed(() => store.state.worksheet === 'Запросы')
  const mode = computed(() => parseInt(worksheetMode.value) ? 'clicks' : 'shows')

  const worksheets = computed(() => {
    let ws = store.state.worksheets
    if (!exceptions.value) return ws
    const sanitizedExceptions = exceptions.value.map(el => el.trim()).filter(el => el).map(el => el.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    if (!sanitizedExceptions.length) return ws
    const exceptionsPattern = new RegExp(sanitizedExceptions.join('|'))
    return ws.map(el => ({
      ...el,
      data: {
        ...el.data,
        table: Object.fromEntries(Object.entries(el.data.table).filter(([key]) => !exceptionsPattern.test(key)))
      }
    }))
  })


  const worksheetdates = computed(() => worksheets.value.map(w => {
    let date = w.date
    return date % 12 === 0 ? `12.${date / 12 - 1}` : `${('' + (date % 12)).padStart(2, '0')}.${(date - date % 12) / 12}`
  }))

  const selectedIndexes = computed(() => selectedDates.value.map(el => worksheetdates.value.indexOf(el)))

  const filteredWorksheets = computed(() =>
    !selectEnabled.value || selectedIndexes.value.length !== 2
      ? worksheets.value
      : worksheets.value.filter((_, i) => selectedIndexes.value.includes(i))
  )

  const filteredDates = computed(() => filteredWorksheets.value.map(w => {
    let date = w.date
    return date % 12 === 0 ? `12.${date / 12 - 1}` : `${('' + (date % 12)).padStart(2, '0')}.${(date - date % 12) / 12}`
  }))

  const percentages = computed(() => worksheets.value.map((w, i) =>
    i === worksheets.value.length - 1 ? '' : Math.round(w.data[mode.value] / worksheets.value[i + 1].data[mode.value] * 10000) / 100
  ))

  const filteredQueries = computed(() => {
    if (!isZaprosi.value) return {}
    const filters = store.state.zaprosiFilters
    if (!filters.length) return {}
    const allqueries = new Set()
    worksheets.value.forEach(ws => Object.keys(ws.data.table).forEach(name => Object.keys(ws.data.table[name].data).forEach(query => allqueries.add(query))))
    const todelete = []
    const f = filters.map(el => {
      const type = getType(el)
      return { type, v: el.replace(type, '') }
    })

    for (let query of allqueries) {
      if (f.find(el => {
        switch (el.type) {
          case '|++': return query == el.v
          case '|--': return query != el.v
          case '|-': return !query.includes(el.v)
          default: return query.includes(el.v)
        }
      }))
        continue
      todelete.push(query)
    }
    for (let query of todelete) allqueries.delete(query)
    return allqueries
  })

  const zaprosiValue = computed(() => {
    if (!isZaprosi.value)
      return {}
    const allNames = {}
    const filters = store.state.zaprosiFilters

    try {
      const mode = worksheetMode.value
      const sortIndex = worksheetdates.value.indexOf(sortdate.value)
      const sortedWorksheet = sortIndex !== -1 ? worksheets.value[sortIndex] : null
      worksheets.value.forEach((ws, index) => Object.keys(ws.data.table || {}).forEach(name => {
        if (!allNames[name]) allNames[name] = { queries: {}, sum: 0, selectedSum: filters.length ? 0 : (sortedWorksheet?.data.table[name]?.sum?.[mode] || 0) }
        allNames[name].sum += ws.data.table[name].sum?.[mode] || 0
        const realQueries = Object.keys(ws.data.table[name].data || {}).filter(query => ws.data.table[name].data[query][mode] && (!filters.length || filteredQueries.value.has(query)))
        for (let query of realQueries) {
          if (allNames[name].queries[query]) allNames[name].queries[query] += ws.data.table[name].data[query][mode]
          else allNames[name].queries[query] = ws.data.table[name].data[query][mode]
        }
        if (index == sortIndex && filters.length) {
          for (let query of realQueries) {
            allNames[name].selectedSum += ws.data.table[name].data[query][mode]
          }
        }
      }))

      for (let name in allNames) {
        allNames[name].sorted = Object.keys(allNames[name].queries || {})
        allNames[name].sorted.sort((a, b) => allNames[name].queries[b] - allNames[name].queries[a])
      }
    } catch (e) {
      console.log('HEREE')
      console.error(e)
      return []
    }
    return allNames
  })


  const names = computed(() => {
    const sortIndex = worksheetdates.value.indexOf(sortdate.value);
    const sortedWorksheet = sortIndex !== -1 ? worksheets.value[sortIndex] : null;
    const mode = worksheetMode.value
    if (!isZaprosi.value) {
      const allNames = new Set();
      worksheets.value.forEach(ws => Object.keys(ws.data.table).forEach(name => allNames.add(name)))

      const sortingValues = {};
      if (sortIndex === -1) {
        allNames.forEach(name => {
          sortingValues[name] = worksheets.value.reduce((sum, ws) => {
            const value = ws.data.table[name]?.[mode] || 0;
            return sum + value;
          }, 0);
        });
      } else {
        allNames.forEach(name => sortingValues[name] = sortedWorksheet?.data.table[name]?.[mode] || 0)
      }

      return [...allNames].sort((a, b) => {
        const direction = sortdate.value && sortreverse.value ? 1 : -1
        return direction * (sortingValues[a] - sortingValues[b])
      });
    }
    else {
      const v = zaprosiValue.value

      const sortingValues = {};
      if (sortIndex === -1) {
        Object.keys(v).forEach(name => sortingValues[name] = v[name]?.sum || 0)
      } else {
        Object.keys(v).forEach(name => sortingValues[name] = sortedWorksheet?.data.table[name]?.sum || 0)
      }
      const direction = sortdate.value && sortreverse.value ? 1 : -1
      return Object.keys(v).sort((a, b) => direction * (sortingValues[a] - sortingValues[b]))
    }
  });

  const filterednames = computed(() =>
    filterregexs.value.length ? names.value.filter(el => filterregexs.value.some(filter => el.match(filter))) : names.value
  )

  const zaprosiValueFiltered = computed(() => {
    if (!isZaprosi.value) return { total: 0, v: { sum: 0, queries: {} } }
    const v = zaprosiValue.value
    const mode = worksheetMode.value
    let res = {}, lim = limit.value
    let names = filterednames.value
    if (sortdate.value) {
      const sortIndex = worksheetdates.value.indexOf(sortdate.value);
      const sortedWorksheet = sortIndex !== -1 ? worksheets.value[sortIndex] : null;
      names = names.map(el => el)
      if (!sortreverse.value)
        names.sort((a, b) => (sortedWorksheet.data.table[b]?.sum?.[mode] || 0) - (sortedWorksheet.data.table[a]?.sum?.[mode] || 0))
      else
        names.sort((a, b) => (sortedWorksheet.data.table[a]?.sum?.[mode] || 0) - (sortedWorksheet.data.table[b]?.sum?.[mode] || 0))
    }

    for (let name of names) {
      if (lim >= v[name].sorted.length) {
        res[name] = v[name].sorted
        lim -= v[name].sorted.length
      }
      else {
        res[name] = v[name].sorted.slice(0, lim)
        lim = 0
      }
    }

    return { total: limit.value - lim, v: res, names: Object.keys(res) }
  })

  const sortedWorksheet = computed(() => {
    const sortIndex = worksheetdates.value.indexOf(sortdate.value);
    return sortIndex !== -1 ? worksheets.value[sortIndex] : null;
  })

  return {
    worksheets,
    worksheetdates,
    filteredWorksheets,
    filteredDates,
    percentages,
    names,
    filterednames,
    zaprosiValue,
    zaprosiValueFiltered,
    isZaprosi,
    mode,
    sortedWorksheet
  }
}