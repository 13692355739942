<template>
  <div class="pdata">
    <div class="pdata__info">
      <div class="pdata__info-selectors" v-if="project">
        <label class="pdata__label">
          Лист:
          <select class="pdata__input" style="display: block;width: 100%;" v-model="worksheet">
            <option value="Страницы">Страницы</option>
            <option value="Запросы">Запросы</option>
            <option value="Страны">Страны</option>
            <option value="Устройства">Устройства</option>
            <option value="Вид в поиске">Вид в поиске</option>
          </select>
        </label>
        <label class="pdata__label">
          Таблица:
          <select class="pdata__input" style="display: block;width: 100%;" v-model="worksheetMode">
            <option value="0">Показы</option>
            <option value="1">Клики</option>
          </select>
        </label>
        <div class="pdata__label pdata__label_exc">
          Фильтры:
          <textarea class="pdata__input pdata__input_textarea" v-model="localFilters"></textarea>
          <Btn :small="true" v-if="exc_changed" class="pdata__btn-exc" @click="excsave">Сохранить изменения</Btn>
        </div>
        <label class="pdata__label pdata__label_check">
          <input type="checkbox" :checked="strictFilterEnabled"
          @change="$store.commit('setStrictFilterEnabled', $event.target.checked)">
          Строгий фильтр
        </label>
        <div class="pdata__label pdata__label_exc" v-if="exceptions && (exceptionlists.includes(worksheet))">
          Исключения:
          <input class="pdata__input" type="text" v-for="(w, index) in exceptions" :key="index"
            v-model="exceptions[index]">
          <input class="pdata__input" type="text" placeholder="Новое исключение..." v-model="newException">
          <Btn :small="true" v-if="exc_changed" class="pdata__btn-exc" @click="excsave">Сохранить изменения</Btn>
        </div>
        <div class="pdata__label pdata__label_exc" v-if="worksheet == 'Запросы'">
          Фильтры запросов:
          <textarea class="pdata__input pdata__input_textarea" v-model="zaprosiFilters"></textarea>
        </div>
        <div v-if="project && Role == 2" class="pdata__loader">
          <label class="pdata__label">
            <span>Загрузите файлы:</span>
            <input class="pdata__input" ref="file" type="file" multiple name="excel">
          </label>
          <label class="pdata__label">
            <span>Дата (только для CSV файлов):</span>
            <input class="pdata__input" type="text" v-maska data-maska="##.##.####" placeholder="01.01.2025"
              v-model="date">
          </label>
          <Btn class="pdata__btn" @click="sendFiles">Загрузить</Btn>
        </div>
      </div>
      <div class="pdata__info-data">
        <Table v-if="worksheets && worksheets.length" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';
import { mapState, mapGetters } from 'vuex'
import Table from './Table.vue';
import { vMaska } from "maska"

export default {
  data: () => ({
    newException: '',
    oldexc: [],
    date: '',
    localFilters: '',
    zaprosiFilters: ''
  }),
  directives: {
    maska: vMaska,
  },
  computed: {
    ...mapState(["projects", "project", "Role", "worksheets", "exceptionlists",'strictFilterEnabled']),
    ...mapGetters(['exceptions']),
    exc_changed() {
      if (!this.exceptions)
        return false
      if (this.newException)
        return true
      if (this.oldexc.length != this.exceptions.length)
        return true
      for (let i in this.oldexc)
        if (this.oldexc[i] !== this.exceptions[i])
          return true
      return false
    },
    worksheet: {
      get() {
        return this.$store.state.worksheet
      },
      set(v) {
        this.$store.commit('setWorksheet', v)
      }
    },
    worksheetMode: {
      get() {
        return this.$store.state.worksheetMode
      },
      set(v) {
        this.$store.commit('setWorksheetMode', v)
      }
    }
  },
  components: { Btn, Table },
  methods: {
    async excsave() {
      let ok = await this.$store.dispatch('saveExceptions', this.newException)
      if (ok) {
        this.newException = ''
      }
    },
    async sendFiles() {
      let a = new FormData()
      for (let file of this.$refs.file.files)
        a.append('files[]', file)
      a.append('project', this.project.name)
      a.append('date', this.date)
      try {
        await this.$store.dispatch('sendFiles', a)
      } catch (err) {
        alert(err)
      }
      await this.$store.dispatch('updateProjects', a)
      this.$refs.file.value = ''
    },
  },
  watch: {
    localFilters(v) {
      this.$store.commit('setFilterList', v.split('\n').map(el => el.trim()).filter(el => el))
    },
    zaprosiFilters(v) {
      this.$store.commit('setZaprosiFilterList', v.split('\n').map(el => el.trim()).filter(el => el))
    },
    worksheet(v, old) {
      if (v && v != old && this.project.name) {
        this.$store.dispatch('getWorksheets')
      }
    },
    'project.name'(v, old) {
      if (v != old) {
        try {
          this.oldexc = this.exceptions.slice(0)
        } catch (err) {
          this.oldexc = []
        }
      }
    },
    projects: {
      handler: function () {
        try {
          this.oldexc = this.exceptions.slice(0)
        } catch (err) {
          this.oldexc = []
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass">
.pdata
  padding-top: 30px
  display: flex
  flex-direction: column
  &__loader
    display: flex
    flex-direction: column
    gap: 10px
  &__getdata
    width: 100% !important
    min-width: 100% !important
    max-width: 100% !important
    flex-shrink: 0 !important
  .filler
    flex-grow: 1
  &__label
    display: block
    &_exc
      >*:not(:last-child)
        margin-bottom: 10px
      .pdata__input:not(:last-child)
        margin-bottom: 5px
    &_check
      display: flex
      align-items: center
      gap: 5px
      cursor: pointer
      input
        width: 18px
        height: 18px
        accent-color: darken(colors.$blue,5)
  &__input
    padding: 5px 8px
    border-radius: 4px
    border: 1px solid rgba(0,0,0,.4)
    outline: none
    display: block
    width: 100%
    
    &:not([type=text])
      cursor: pointer
    &:focus
      border-color: rgba(0,0,0,.8)
    &_textarea
      cursor: text !important
      @supports (field-sizing: content)
        field-sizing: content
        resize: none
        min-height: 1lh
      @supports not (field-sizing: content)
        resize: vertical
        min-height: 4lh
  &__info
    display: flex
    flex-grow: 1
    align-items: stretch
    &-data
      flex-grow: 1
      display: flex
      flex-direction: column
    &-selectors
      width: 200px
      flex-shrink: 0
      position: sticky
      top: 10px
      min-height: calc(100vh - 80px)
      align-self: flex-start
      display: flex
      flex-direction: column
      margin-right: 15px
      >*
        margin-bottom: 10px
  &__doublebtns
    display: flex
    justify-content: space-between
    position: relative
    &-dash
      width: auto !important
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: 0
      font-size: 16px
      line-height: 30px
    >*
      width: calc(50% - 12.5px)
  &__btn
    width: 100%
    align-self: stretch
    font-size: 18px
    &-exc
      width: 100%
    &_spaced
      margin-top: 20px
</style>