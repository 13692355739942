import { useStore } from 'vuex'
import ExcelJS from 'exceljs'

export function useTableOperations() {
  const store = useStore()

  const deleteWorksheets = async (index, selectEnabled, worksheetdates) => {
    if (selectEnabled.value) return alert("Для удаления столбца необходимо выйти из сравнения")
    if (confirm(`Удалить данные за ${worksheetdates[index]} ?`)) {
      await store.dispatch('deleteWorksheets', index)
    }
  }

  const save = async ({
    project,
    filteredWorksheets,
    filteredDates,
    filterednames,
    zaprosiValue,
    worksheetMode,
    isZaprosi,
    summaryRows,
    getSummaryValue,
    getSummaryClass,
    getCellClass,
    green,
    red
  }) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Данные')
    if (isZaprosi)
      worksheet.columns = '40,40'.split(',').concat(filteredWorksheets.map(() => '15')).map(w => ({ width: +w }))
    else
      worksheet.columns = '40'.split(',').concat(filteredWorksheets.map(() => '15')).map(w => ({ width: +w }))

    summaryRows.forEach(row => {
      const rowData = [row.label, ...(isZaprosi ? [''] : []), ...filteredWorksheets.map((w, i) => getSummaryValue(row, w, i))]
      const excelRow = worksheet.addRow(rowData)
      let currentCol = isZaprosi ? 2 : 1
      if (isZaprosi) worksheet.mergeCells(`A${excelRow.number}:B${excelRow.number}`)
      filteredWorksheets.forEach((_, i) => {
        const cell = excelRow.getCell(currentCol + i + 1)
        const fill = getSummaryClass(row, i) === 'good' ? green : getSummaryClass(row, i) === 'bad' ? red : null
        if (fill) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        cell.font = { bold: true }
      })
    })

    const datesRow = ['Дата:', ...(isZaprosi ? [''] : []), ...filteredDates]
    const excelRow = worksheet.addRow(datesRow)
    if (isZaprosi) worksheet.mergeCells(`A${excelRow.number}:B${excelRow.number}`)
    for (let i = isZaprosi ? 2 : 1; i < datesRow.length + (isZaprosi ? 1 : 0); i++) {
      excelRow.getCell(i + 1).alignment = { horizontal: 'center', vertical: 'middle' }
    }

    if (isZaprosi) {
      for (let name in zaprosiValue) {
        for (let query of zaprosiValue[name].sorted) {
          const excelRow = worksheet.addRow([name, query, ...filteredWorksheets.map(w => w.data.table[name]?.data?.[query]?.[worksheetMode]) || 0])
          let currentCol = 2
          filteredWorksheets.forEach((_, i) => {
            const cell = excelRow.getCell(currentCol + i + 1)
            const fill = getCellClass(name, i, query) === 'good' ? green : getCellClass(name, i) === 'bad' ? red : null
            if (fill) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } }
            cell.alignment = { horizontal: 'center', vertical: 'middle' }
            cell.numFmt = '0'
          })
        }
      }
    } else {
      filterednames.forEach(name => {
        //const rowData = [name, ...(isZaprosi ? [zaproslandings[name]] : []), ...filteredWorksheets.map(w => w.data.table[name]?.[worksheetMode] || 0)]
        const rowData = [name, ...filteredWorksheets.map(w => w.data.table[name]?.[worksheetMode] || 0)]
        const excelRow = worksheet.addRow(rowData)
        //let currentCol = isZaprosi ? 2 : 1
        let currentCol = 1
        filteredWorksheets.forEach((_, i) => {
          const cell = excelRow.getCell(currentCol + i + 1)
          const fill = getCellClass(name, i) === 'good' ? green : getCellClass(name, i) === 'bad' ? red : null
          if (fill) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } }
          cell.alignment = { horizontal: 'center', vertical: 'middle' }
        })
      })
    }

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${project.name}.xlsx`
    a.click()
    URL.revokeObjectURL(url)
  }

  return { deleteWorksheets, save }
}