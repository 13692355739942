import { ref, computed } from 'vue'

export function useTableUiRefs() {
  const sortdate = ref(false)
  const sortreverse = ref(false)
  const selectEnabled = ref(false)
  const selectedDates = ref([])
  const limit = ref(100)
  return { sortdate, sortreverse, selectEnabled, selectedDates, limit }
}

export function useTableUI({ sortdate, sortreverse, selectEnabled, selectedDates, limit }, isZaprosi, filteredWorksheets, filterednames, worksheetMode) {
  const green = 'FFC8FFC8'
  const red = 'FFFFC8C8'

  const displayedNames = computed(() => {
    if (!isZaprosi.value)
      return filterednames.value.slice(0, limit.value)
    return []
  })

  const setSortDate = (date) => {
    sortreverse.value = sortdate.value === date ? !sortreverse.value : false
    sortdate.value = date
  }

  const selectDate = (date) => {
    selectedDates.value = selectedDates.value.includes(date)
      ? selectedDates.value.filter(el => el !== date)
      : selectedDates.value.length === 2 ? [selectedDates.value[0], date] : [...selectedDates.value, date]
  }

  const toggleLimit = () => {
    limit.value = limit.value === 100 ? 5000 : 100
  }

  const toggleComparison = () => {
    selectEnabled.value = !selectEnabled.value
  }

  const getSummaryClass = (row, index) => {
    if (index === filteredWorksheets.value.length - 1 || !row.compare) return ''
    return row.label === 'Процент от прошлого месяца:'
      ? row.compare(row.valueFn(null, index))
      : row.compare(row.valueFn(filteredWorksheets.value[index]), row.valueFn(filteredWorksheets.value[index + 1]))
  }

  const getCellClass = (name, index, query) => {
    if (index === filteredWorksheets.value.length - 1) return ''
    let current, next
    if (query === undefined) {
      current = filteredWorksheets.value[index].data.table[name]?.[worksheetMode.value] || 0
      next = filteredWorksheets.value[index + 1].data.table[name]?.[worksheetMode.value] || 0
    } else {      
      current = filteredWorksheets.value[index].data.table[name]?.data?.[query]?.[worksheetMode.value] || 0
      next = filteredWorksheets.value[index + 1].data.table[name]?.data?.[query]?.[worksheetMode.value] || 0
    }
    return current < next ? 'bad' : current > next ? 'good' : ''
  }

  return {
    displayedNames,
    setSortDate,
    selectDate,
    toggleLimit,
    toggleComparison,
    getSummaryClass,
    getCellClass,
    green,
    red,
  }
}